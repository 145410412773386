<template>
<div role="dialog" aria-modal="true">
    <linkbutton :toplabel="title" @click="Forgot()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <div >
                <form ref="changepassform" v-on:keyup.enter="SaveNewPassword">
                <h1><label class="titlelabel">{{title}}</label></h1>

                <formentrystacked ref="oldpwd" inputid="old"  label="* School Email" v-model="emailaddress"  isrequired="true" autocomplete="email" @isshifttab="shifttaboninput($event)" />

                <label for="">* Required field</label>
                <br>

                <smallbutton ref="gobutton" @click="Go()" toplabel="Forgot Password" />
                <smallbutton ref="cancelbutton"    @click="$refs.mymodal.closeModal()" toplabel='Cancel' buttonclass="button buttonhighlight" @istab="taboncancel($event)"  />
                
                </form>
            </div>
            <div>
                <label class="responsemessage">{{responseMessage}}</label>
            </div>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../services/datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentrystacked from '../controls/entry-stacked.vue'
import linkbutton from '../controls/linkbutton.vue'
export default {
    name:'forgotpassword',
    components:{
        smallbutton,
        actionmodal,
        formentrystacked,
        linkbutton
    },
    props:{
        title:{
            type:String,
            default:'Forgot Password'
        },
        username:{
            type:String,
        },

    },
    data(){
        return{
            emailaddress:'',
            responseMessage:null
        }
    },

    methods:{
        taboncancel(event){
            console.log('tab2' + event)
            event.preventDefault()
            this.$nextTick(()=>{
                this.$refs.oldpwd.focus()
            });
        },
    
       
        shifttaboninput(event){
            console.log('shifttab on input' )
            event.preventDefault()
            this.$nextTick(()=>{
                this.$refs.cancelbutton.focus()
            });
        },
        Forgot(){
            this.emailaddress=this.username
            this.$refs.mymodal.openModal();
            this.$nextTick(()=>{
                this.$refs.oldpwd.focus()
            });
        },
        Go(){
            this.$emit('go',this.emailaddress)
            this.$refs.mymodal.closeModal()
        }
        
    
    },
    mounted(){
        
        
    }
}
</script>

<style>

</style>

































       